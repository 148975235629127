import React, { useState } from 'react';
import Map, { Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import sectorsGeoJson from './sectors.geojson';
import tmaGeoJson from './tma.geojson';
import firsJson from './firs.json';
import pointsGeoJson from './points.geojson';

const mapboxToken = 'pk.eyJ1Ijoib3R0b3R1aGt1bmVuIiwiYSI6ImNseG41dW9vaDAwNzQycXNleWI1MmowbHcifQ.1ZMRPeOQ7z9GRzKILnFNAQ';

const controllerList = [
  'EFIN_A_CTR', 'EFIN_B_CTR', 'EFIN_C_CTR', 'EFIN_D_CTR', 'EFIN_E_CTR', 
  'EFIN_F_CTR', 'EFIN_G_CTR', 'EFIN_H_CTR', 'EFIN_J_CTR', 'EFIN_K_CTR',
  'EFIN_L_CTR', 'EFIN_M_CTR', 'EFIN_N_CTR', 'EFIN_V_CTR'
];

const sectorsOwnership = {
  sector1: ['A', 'D', 'C'],
  sector2: ['B', 'C', 'D'],
  sector3: ['C', 'D'],
  sector4: ['D', 'C'],
  sector5: ['E', 'F', 'D', 'C'],
  sector6: ['F', 'D', 'C'],
  sector7: ['G', 'F', 'D', 'C'],
  sector8: ['H', 'V', 'M', 'G', 'F', 'D'],
  sector9: ['J', 'H', 'V', 'M', 'G', 'F', 'D'],
  sector10: ['K', 'M', 'G', 'F', 'D', 'C'],
  sector11: ['L', 'N', 'M', 'G', 'F', 'D', 'C'],
  sector12: ['M', 'G', 'F', 'D', 'C'],
  sector13: ['N', 'M', 'G', 'F', 'D', 'C'],
  sector14: ['V', 'M', 'G', 'F', 'D', 'C']
};

const controllerColors = {
  'EFIN_A_CTR': '#2f4f4f',
  'EFIN_B_CTR': '#8b0000',
  'EFIN_C_CTR': '#ff8c00',
  'EFIN_D_CTR': '#00bfff',
  'EFIN_E_CTR': '#ffff00',
  'EFIN_F_CTR': '#006400',
  'EFIN_G_CTR': '#00ff00',
  'EFIN_H_CTR': '#deb887',
  'EFIN_J_CTR': '#0000cd',
  'EFIN_K_CTR': '#dda0dd',
  'EFIN_L_CTR': '#191970',
  'EFIN_M_CTR': '#ff1493',
  'EFIN_N_CTR': '#98fb98',
  'EFIN_V_CTR': '#ff4500'
};

const controllerLabels = {
  'EFIN_A_CTR': 'EFIN A',
  'EFIN_B_CTR': 'EFIN B',
  'EFIN_C_CTR': 'EFIN C',
  'EFIN_D_CTR': 'EFIN D',
  'EFIN_E_CTR': 'EFIN E',
  'EFIN_F_CTR': 'EFIN F',
  'EFIN_G_CTR': 'EFIN G',
  'EFIN_H_CTR': 'EFIN H',
  'EFIN_J_CTR': 'EFIN J',
  'EFIN_K_CTR': 'EFIN K',
  'EFIN_L_CTR': 'EFIN L',
  'EFIN_M_CTR': 'EFIN M',
  'EFIN_N_CTR': 'EFIN N',
  'EFIN_V_CTR': 'EFIN V'
};

const presets = {
  'D + F': ['EFIN_D_CTR', 'EFIN_F_CTR'],
  'D + G': ['EFIN_D_CTR', 'EFIN_G_CTR'],
  'D + M': ['EFIN_D_CTR', 'EFIN_M_CTR'],
  'D + V': ['EFIN_D_CTR', 'EFIN_V_CTR'],
  'D + F + V': ['EFIN_D_CTR', 'EFIN_F_CTR', 'EFIN_V_CTR'],
  'D + G + V': ['EFIN_D_CTR', 'EFIN_G_CTR', 'EFIN_V_CTR'],
  'D + M + V': ['EFIN_D_CTR', 'EFIN_M_CTR', 'EFIN_V_CTR'],
  'D + F + M': ['EFIN_D_CTR', 'EFIN_F_CTR', 'EFIN_M_CTR'],
  'D + F + M + V': ['EFIN_D_CTR', 'EFIN_F_CTR', 'EFIN_M_CTR', 'EFIN_V_CTR']
};


const getSectorOwner = (sectorCode, onlineControllers) => {
  const sectorControllers = sectorsOwnership[sectorCode];
  for (let controller of sectorControllers) {
    const callsign = `EFIN_${controller}_CTR`;
    if (onlineControllers.includes(callsign)) {
      return callsign;
    }
  }
  return null;
};

const App = () => {
  const [onlineControllers, setOnlineControllers] = useState(['EFIN_D_CTR']);
  const [activePreset, setActivePreset] = useState(null);

  const toggleController = (callsign) => {
    setOnlineControllers(prev =>
      prev.includes(callsign)
        ? prev.filter(c => c !== callsign)
        : [...prev, callsign]
    );
    setActivePreset(null); // Reset preset when a controller is toggled
  };

  const selectPreset = (presetName) => {
    setOnlineControllers(presets[presetName] || []);
    setActivePreset(presetName);
  };

  const getSectorFillColor = (sectorCode) => {
    const owner = getSectorOwner(sectorCode, onlineControllers);
    return owner ? controllerColors[owner] : 'rgb(50, 50, 50)'; // dark gray as default
  };


  return (
    <div style={{ height: '100vh', backgroundColor: '#1e1e1e', color: '#ffffff' }}>
      {/* Controller buttons */}
      <div style={{ 
        position: 'fixed',
        top: '0',
        width: '100%',
        paddingTop: '10px', 
        paddingBottom: '10px', 
        display: 'flex', 
        flexWrap: 'wrap', 
        background: '#011328', 
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '100'
      }}>
        {controllerList.map((controller) => (
          <button
            key={controller}
            style={{
              margin: '5px',
              padding: '10px',
              background: onlineControllers.includes(controller) ? controllerColors[controller] : '#555555',
              color: onlineControllers.includes(controller) ? '#000000' : '#ffffff',
              fontWeight: onlineControllers.includes(controller) ? 'bold' : 'normal',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
            onClick={() => toggleController(controller)}
          >
            {controllerLabels[controller]}
          </button>
        ))}
      </div>

      {/* Preset buttons */}
        <div style={{ 
          position: 'fixed',
          bottom: '0',
          width: '100%',
          paddingTop: '10px', 
          paddingBottom: '10px', 
          display: 'flex', 
          flexWrap: 'wrap', 
          background: '#011328', 
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
          zIndex: '100'
        }}>
        {Object.keys(presets).map((presetName) => (
          <button
            key={presetName}
            style={{
              margin: '5px',
              padding: '10px',
              background: activePreset === presetName ? '#1a475f' : '#555555',
              color: activePreset === presetName ? '#ffffff' : '#ffffff',
              fontWeight: activePreset === presetName ? 'bold' : 'normal',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
            onClick={() => selectPreset(presetName)}
          >
            {presetName}
          </button>
        ))}
      </div>

      {/* Map and sectors */}
      <Map
        initialViewState={{
          longitude: 25,
          latitude: 65,
          zoom: 3.8
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/ottotuhkunen/cm16espeq020301qu8sxogcp8"
        mapboxAccessToken={mapboxToken}
      >
        {/* TMA geojson */}
        <Source type="geojson" data={tmaGeoJson}>
          <Layer
            id="tma-lines"
            type="line"
            paint={{
              'line-color': '#00bfff',
              'line-width': 0.7,
              'line-dasharray': [4, 2]
            }}
          />

          <Layer
            id="tma-labels"
            type="symbol"
            layout={{
              'text-field': ['concat', ['get', 'longcode']],
              'text-size': 10,
              'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],  // Changed to regular fonts
              'text-offset': [0, 0.6]
            }}
            paint={{
              'text-color': '#ffffff',
              'text-halo-color': '#000000',
              'text-halo-width': 1
            }}
            minzoom={5}
          />
        </Source>

        {/* FIRs geojson */}
        <Source type="geojson" data={firsJson}>
          <Layer
            id="firs-lines"
            type="line"
            paint={{
              'line-color': '#006400',
              'line-width': 1.5
            }}
          />
        </Source>

        <Source type="geojson" data={sectorsGeoJson}>
          <Layer
            id="sectors-fill"
            type="fill"
            paint={{
              'fill-color': [
                'match',
                ['get', 'code'],
                'sector1', getSectorFillColor('sector1'),
                'sector2', getSectorFillColor('sector2'),
                'sector3', getSectorFillColor('sector3'),
                'sector4', getSectorFillColor('sector4'),
                'sector5', getSectorFillColor('sector5'),
                'sector6', getSectorFillColor('sector6'),
                'sector7', getSectorFillColor('sector7'),
                'sector8', getSectorFillColor('sector8'),
                'sector9', getSectorFillColor('sector9'),
                'sector10', getSectorFillColor('sector10'),
                'sector11', getSectorFillColor('sector11'),
                'sector12', getSectorFillColor('sector12'),
                'sector13', getSectorFillColor('sector13'),
                'sector14', getSectorFillColor('sector14'),
                'rgb(50, 50, 50)' // Default color (dark gray)
              ],
              'fill-opacity': 0.3
            }}
          />

          <Layer
            id="sectors-border"
            type="line"
            paint={{
              'line-color': 'lightgray',
              'line-width': 1
            }}
          />

          <Layer
            id="sectors-labels"
            type="symbol"
            layout={{
              'text-field': ['concat', ['get', 'name'], '\n', ['get', 'frequency']], // Shows 'name (frequency)'
              'text-size': 10,
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
              'text-anchor': 'center',
            }}
            paint={{
              'text-color': 'lightgray',
              'text-halo-color': '#000000',
              'text-halo-width': 0.6
            }}
          />

        </Source>

        <Source id="points" type="geojson" data={pointsGeoJson}>
          <Layer
            id="points-symbol"
            type="symbol"
            layout={{
              'icon-image': [
                'case',
                ['all', ['==', ['get', 'departure'], true], ['==', ['get', 'arrival'], true]], 'fix-all',
                ['==', ['get', 'departure'], true], 'fix-dep',
                ['==', ['get', 'arrival'], true], 'fix-arr',
                'border-dot-13'
              ],
              'icon-size': 1,
              'text-field': ['get', 'name'],
              'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
              'text-size': 10,
              'text-offset': [0.6, 0.6],
              'text-anchor': 'left',
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
            }}
            paint={{
              'text-color': 'lightgray',
              'text-halo-color': '#000000',
              'text-halo-width': 1
            }}
            minzoom={6.4}
          />
        </Source>


      </Map>
    </div>
  );
};

export default App;
